import { createRouter, createWebHistory } from "vue-router";
import PflanzenTypen from "@/views/PflanzenTypen.vue";
import PflanzenTypNeu from "@/views/PflanzenTypNeu.vue";
import PflanzenTyp from "@/views/PflanzenTyp.vue";
import Pflanzen from "@/views/Pflanzen.vue";
import PflanzeNeu from "@/views/PflanzeNeu.vue";
import Pflanze from "@/views/Pflanze.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/pflanzentypen",
  },
  {
    path: '/pflanzentypen',
    name: 'Pflanzentypen',
    component: PflanzenTypen,
  },
  {
    path: '/pflanzentyp/neu',
    name: 'Neuer Pflanzentyp',
    component: PflanzenTypNeu,
  },
  {
    path: '/pflanzentyp/:id',
    name: 'Pflanzentyp',
    component: PflanzenTyp,
  },
  {
    path: '/pflanzen',
    name: 'Pflanzen',
    component: Pflanzen,
  },
  {
    path: '/pflanze/neu',
    name: 'Neue Pflanze',
    component: PflanzeNeu,
  },
  {
    path: '/pflanze/:id',
    name: 'Pflanze',
    component: Pflanze,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    // component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    // component: SignUp,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
