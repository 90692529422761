<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6 v-if="!selected_pflanzentyp">Pflanzentyp wählen</h6>
      <h6 v-else>Name wählen</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mx-4 mb-3">
      <form @submit.prevent.stop="submit">
        <soft-input name="name" required></soft-input>
        <soft-button type="submit"> Pflanzentyp erstellen </soft-button>
      </form>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import { collection, getFirestore, addDoc } from "firebase/firestore";
import moment from "moment";

export default {
  name: "authors-table",
  data() {
    return {
      moment,
      selected_pflanzentyp: null,
    };
  },
  components: {
    SoftInput,
    SoftButton,
  },
  methods: {
    async submit(ev) {
      let data = Object.fromEntries(new FormData(ev.target));
      let pflanze = await addDoc(collection(getFirestore(), "/pflanzentypen"), {
        name: data.name,
      });
      this.$router.push({
        name: "Pflanzentyp",
        params: { id: pflanze.id },
      });
    },
  },
};
</script>
