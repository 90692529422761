<template>
  <div class="form-check">
    <input
      :id="id"
      class="form-check-input"
      type="checkbox"
      :name="name"
      v-model="value_internal"
    />
    <label :for="id" class="custom-control-label" :class="$attrs.class">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "SoftCheckbox",
  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value_internal: this.checked,
    }
  },
  watch: {
    value_internal(val) {
      this.$emit('input', val);
    }
  },
  model: ["checked", 'input']
};
</script>
