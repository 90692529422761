<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6 v-if="!selected_pflanzentyp">Pflanzentyp wählen</h6>
      <h6 v-else>Name wählen</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent.stop="submit">
        <template v-if="!selected_pflanzentyp">
          <table v-if="pflanzentypen" class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Infos
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="pflanzentyp in pflanzentypen"
                :key="pflanzentyp.id"
                class="cursor-pointer"
                @click="selected_pflanzentyp = pflanzentyp.id"
              >
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <soft-avatar
                        :img="img1"
                        size="sm"
                        border-radius="lg"
                        class="me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ pflanzentyp.name }}</h6>
                      <p
                        v-if="getPflanzen(pflanzentyp.id).length"
                        class="text-xs text-secondary mb-0"
                      >
                        {{
                          getPflanzen(pflanzentyp.id).length == 1
                            ? "1 Pflanze vorhanden"
                            : `${
                                getPflanzen(pflanzentyp.id).length
                              } Pflanzen vorhanden`
                        }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p
                    v-if="pflanzentyp.gießenDays && pflanzentyp.gießenDays != 0"
                    class="text-xs mb-0"
                  >
                    <span class="font-weight-bold">Gießen </span>
                    <span
                      v-if="pflanzentyp.gießenDays == 1"
                      class="text-secondary"
                    >
                      jeden Tag
                    </span>
                    <span v-else class="text-secondary">
                      aller {{ pflanzentyp.gießenDays }} Tage
                    </span>
                  </p>
                  <p
                    v-if="pflanzentyp.düngenDays && pflanzentyp.düngenDays != 0"
                    class="text-xs mb-0"
                  >
                    <span class="font-weight-bold">Düngen </span>
                    <span
                      v-if="pflanzentyp.düngenDays == 1"
                      class="text-secondary"
                    >
                      jeden Tag
                    </span>
                    <span v-else class="text-secondary">
                      aller {{ pflanzentyp.düngenDays }} Tage
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="pflanzentypen && pflanzentypen.length == 0"
            class="text-center mt-4 mb-3"
          >
            Es sind noch keine Pflanzentypen da 🥲
            <br />
            <a href="javascript:;" @click="$router.push({
              name: 'Neuer Pflanzentyp'
            })">Jetzt den ersten Pflanzentyp anlegen</a>
          </div>
        </template>
        <div v-else class="mx-4 mb-4">
          <div class="mb-2">
            <span class="font-weight-bolder">Pflanzentyp: </span>
            {{
              pflanzentypen
                ? pflanzentypen.find((pt) => pt.id == selected_pflanzentyp).name
                : ""
            }}
          </div>
          <soft-input name="name" required></soft-input>
          <soft-button type="submit"> Pflanze erstellen </soft-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import { collection, getFirestore, addDoc, doc } from "firebase/firestore";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "authors-table",
  props: {
    pflanzentyp: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      moment,
      selected_pflanzentyp: null,
    };
  },
  components: {
    SoftInput,
    SoftButton,
  },
  computed: {
    ...mapState(["pflanzen", "pflanzentypen"]),
  },
  watch: {
    pflanzentypen() {
      this.updateParams();
    },
  },
  methods: {
    getPflanzen(typ_id) {
      return (this.pflanzen || []).filter(
        (pflanze) => pflanze.pflanzentyp && pflanze.pflanzentyp.id == typ_id
      );
    },
    updateParams() {
      this.selected_pflanzentyp =
        this.pflanzentypen &&
        this.pflanzentypen.find((pt) => pt.id == this.pflanzentyp)
          ? this.pflanzentypen.find((pt) => pt.id == this.pflanzentyp).id
          : null;
    },
    async submit(ev) {
      let data = Object.fromEntries(new FormData(ev.target));
      let pflanze = await addDoc(collection(getFirestore(), "/pflanzen"), {
        pflanzentyp: doc(
          getFirestore(),
          `/pflanzentypen/${this.selected_pflanzentyp}`
        ),
        name: data.name,
        benachrichtigungGießen: true,
        benachrichtigungDüngen: true,
      });
      this.$router.push({
        name: "Pflanze",
        params: { id: pflanze.id },
      });
    },
  },
  created() {
    this.updateParams();
  },
};
</script>
