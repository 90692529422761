// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAFXKg19RLSo2aungh7QrIutpP5i8XiTig",
  authDomain: "lisas-pflanzen.firebaseapp.com",
  projectId: "lisas-pflanzen",
  storageBucket: "lisas-pflanzen.appspot.com",
  messagingSenderId: "851977991736",
  appId: "1:851977991736:web:e12b244c9946d44d3658cd"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

import { getFirestore, doc, setDoc } from "firebase/firestore";

export const db = getFirestore();

import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";

export const messaging = getMessaging();

isSupported().then(() => {
  Notification.requestPermission()
    .then(async () => {
      const sw = await window.navigator.serviceWorker.register('/registerServiceWorker.js');
      getToken(messaging, {
        vapidKey: "BBAQ0qKs2YLHYKJ2WaQ8Vp6hV1wpjILqh-w4gobddBEfc5ZazDvBR1eixN6Yo8M23GWmzIm0BOHxPHSqepl15Cg",
        serviceWorkerRegistration: sw,
      })
      .then(token => {
        setDoc(doc(db, `push_tokens/${token}`), {
          token
        });
        onMessage(messaging, (message) => {
          console.log(message);
        });
      });
    });
});
