<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <h6 class="col">Pflanzentypen</h6>
        <div class="col-auto text-right">
          <soft-button
            @click="
              $router.push({
                name: 'Neuer Pflanzentyp',
              })
            "
            >Neuer Pflanzentyp</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table v-if="pflanzentypen" class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Infos
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pflanzentyp in pflanzentypen" :key="pflanzentyp.id">
              <td>
                <div class="d-flex px-2 py-1 cursor-pointer" @click="
                    $router.push({
                      name: 'Pflanzentyp',
                      params: { id: pflanzentyp.id },
                    })
                  ">
                  <div>
                    <soft-avatar
                      v-if="false"
                      :img="null"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ pflanzentyp.name }}</h6>
                    <p
                      v-if="getPflanzen(pflanzentyp.id).length"
                      class="text-xs text-secondary mb-0"
                    >
                      {{ getPflanzen(pflanzentyp.id).length == 1 ? '1 Pflanze vorhanden' : `${getPflanzen(pflanzentyp.id).length} Pflanzen vorhanden` }} 
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p v-if="pflanzentyp.gießenDays && pflanzentyp.gießenDays != 0" class="text-xs mb-0">
                  <span class="font-weight-bold">Gießen </span>
                  <span v-if="pflanzentyp.gießenDays == 1" class="text-secondary">
                    jeden Tag
                  </span>
                  <span v-else class="text-secondary">
                    aller {{ pflanzentyp.gießenDays }} Tage
                  </span>
                </p>
                <p v-if="pflanzentyp.düngenDays && pflanzentyp.düngenDays != 0" class="text-xs mb-0">
                  <span class="font-weight-bold">Düngen </span>
                  <span v-if="pflanzentyp.düngenDays == 1" class="text-secondary">
                    jeden Tag
                  </span>
                  <span v-else class="text-secondary">
                    aller {{ pflanzentyp.düngenDays }} Tage
                  </span>
                </p>
              </td>
              <td class="align-middle">
                <a
                  href="javascript:;"
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Pflanze bearbeiten"
                  @click="
                    $router.push({
                      name: 'Pflanzentyp',
                      params: { id: pflanzentyp.id },
                    })
                  "
                  >Details</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="pflanzentypen && pflanzentypen.length == 0" class="text-center mt-4 mb-3">
          Es sind noch keine Pflanzentypen da 🥲
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftButton from "@/components/SoftButton.vue";
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  name: "authors-table",
  data() {
    return {
      moment,
   };
  },
  components: {
    SoftAvatar,
    SoftButton,
  },
  computed: {
    ...mapState(["pflanzentypen"]),
    ...mapGetters(["pflanzen"]),
  },
  watch: {
    pflanzen: {
      handler() {
        // this.updateFiles();
      }
    }
  },
  methods: {
    getPflanzen(typ_id) {
      return this.pflanzen.filter(pflanze => pflanze.pflanzentyp && pflanze.pflanzentyp.id == typ_id)
    },
  },
};
</script>
