<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <pflanzen-typen-table />
      </div>
    </div>
  </div>
</template>

<script>
import PflanzenTypenTable from "./components/PflanzenTypenTable";

export default {
  name: "tables",
  components: {
    PflanzenTypenTable,
  },
};
</script>
