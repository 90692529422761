<template>
  <template v-if="!data">
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                src="@/assets/img/bruce-mars.jpg"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">Lädt...</h5>
            </div>
          </div>
        </div>
      </div>
    </div></template
  >
  <template v-else>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                v-if="false"
                src="@/assets/img/bruce-mars.jpg"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
              <div v-else>kein Bild</div>
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ data.name }}</h5>
              <p v-if="data.lat_name" class="mb-0 text-sm font-weight-bold">
                {{ data.lat_name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="mt-3 row">
        <div class="col-12 col-md-6 col-xl-4">
          <div class="card h-100">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-0">Details</h6>
            </div>
            <div class="p-3 card-body">
              <h6 class="text-xs text-uppercase text-body font-weight-bolder">
                Allgemeines
              </h6>
              <form @submit.stop.prevent="submit">
                <span class="font-weight-bold">Name</span>
                <soft-input name="name" :value="data.name"></soft-input>
                <span class="font-weight-bold">Anzahl Tage gießen</span>
                <soft-input
                  name="gießenDays"
                  type="number"
                  :value="data.gießenDays"
                  placeholder="(noch) nicht definiert"
                ></soft-input>
                <span class="font-weight-bold">Anzahl Tage düngen</span>
                <soft-input
                  name="düngenDays"
                  type="number"
                  :value="data.düngenDays"
                  placeholder="(noch) nicht definiert"
                ></soft-input>
                <soft-button type="submit" :disabled="submitLoading">
                  Daten aktualisieren
                </soft-button>
                <small v-if="submitShowSuccess" class="mx-3 text-success"
                  >Daten aktualisiert</small
                >
                <small v-if="submitShowError" class="mx-3 text-warning"
                  >Fehler aufgetreten</small
                >
              </form>
              <h6
                class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
              >
                Benachrichtigungen
              </h6>
              <ul class="list-group">
                <li class="px-0 border-0 list-group-item">
                  <soft-switch
                    id="flexSwitchCheckDefault3"
                    name="Project Launch"
                    class="ps-0 ms-auto"
                    label-class="mb-0 text-body ms-3 text-truncate w-80"
                    >New launches and projects</soft-switch
                  >
                </li>
                <li class="px-0 border-0 list-group-item">
                  <soft-switch
                    id="flexSwitchCheckDefault4"
                    name="Product Update"
                    class="ps-0 ms-auto"
                    label-class="mb-0 text-body ms-3 text-truncate w-80"
                    checked
                    >Monthly product updates</soft-switch
                  >
                </li>
                <li class="px-0 pb-0 border-0 list-group-item">
                  <soft-switch
                    id="flexSwitchCheckDefault5"
                    name="Newsletter"
                    class="ps-0 ms-auto"
                    label-class="mb-0 text-body ms-3 text-truncate w-80"
                    >Subscribe to newsletter</soft-switch
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
          <pflanzen-typ-info-card
            title="Profile Information"
            description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
            :info="{
              fullName: 'Alec M. Thompson',
              mobile: '(44) 123 1234 123',
              email: 'alecthompson@mail.com',
              location: 'USA',
            }"
            :social="[
              {
                link: 'https://www.facebook.com/CreativeTim/',
                icon: faFacebook,
              },
              {
                link: 'https://twitter.com/creativetim',
                icon: faTwitter,
              },
              {
                link: 'https://www.instagram.com/creativetimofficial/',
                icon: faInstagram,
              },
            ]"
            :action="{
              route: 'javascript:;',
              tooltip: 'Edit Profile',
            }"
          />
        </div>
        <div class="mt-4 col-12 col-xl-4 mt-xl-0">
          <div class="card h-100">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-0">Conversations</h6>
            </div>
            <div class="p-3 card-body">
              <ul class="list-group">
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="sophie"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Sophie B.</h6>
                    <p class="mb-0 text-xs">Hi! I need more information..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="marie"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Anne Marie</h6>
                    <p class="mb-0 text-xs">Awesome work, can you..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="ivana"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Ivanna</h6>
                    <p class="mb-0 text-xs">About files I can..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="peterson"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Peterson</h6>
                    <p class="mb-0 text-xs">Have a great afternoon..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
                <li
                  class="px-0 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="nick"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">Nick Daniel</h6>
                    <p class="mb-0 text-xs">Hi! I need more information..</p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    >Reply</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-12">
          <div class="mb-4 card">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-1">Pflanzen</h6>
            </div>
            <div class="p-3 card-body">
              <div class="row">
                <default-pflanze-card
                  v-for="pflanze of pflanzen"
                  :key="pflanze.id"
                  :title="pflanze.name || 'ohne Name 😒'"
                  :image="pflanzen_image_links[pflanze.id] || unknownImg"
                  :label="
                    pflanze.pflanzentyp
                      ? pflanze.pflanzentyp.name
                      : 'Unbekannt 🤷‍♂️'
                  "
                  :description="pflanze.beschreibung"
                  :action="{
                    color: 'success',
                    label: 'Pflanze anzeigen',
                    clickHandler() {
                      $router.push({
                        name: 'Pflanze',
                        params: {
                          id: pflanze.id,
                        },
                      });
                    },
                  }"
                />

                <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
                  <place-holder-card
                    :title="{ text: 'Neue Pflanze', variant: 'h5' }"
                    @click="
                      $router.push({
                        name: 'Neue Pflanze',
                        query: { pflanzentyp: data.id },
                      })
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <a v-if="pflanzen.length == 0" href="#" @click="remove">
        <i
          class="text-sm fas fa-trash text-secondary"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Löschen"
        ></i>
        Pflanzentyp Löschen
      </a>
      <span v-else class="small text-secondary"
        >Pflanzentypen können nur gelöscht werden, wenn es keine aktiven
        Pflanzen mehr gibt</span
      >
    </div>
  </template>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import PflanzenTypInfoCard from "./components/PflanzenTypInfoCard.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import unknownImg from "@/assets/img/unknown.jpeg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import DefaultPflanzeCard from "./components/DefaultPflanzeCard.vue";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { updateDoc, doc, getFirestore, deleteDoc } from "@firebase/firestore";
import { getStorage, ref, getDownloadURL } from "@firebase/storage";

export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    SoftInput,
    SoftButton,
    PflanzenTypInfoCard,
    SoftAvatar,
    DefaultPflanzeCard,
    PlaceHolderCard,
  },
  data() {
    return {
      submitLoading: false,
      submitShowSuccess: false,
      submitShowError: false,
      // pflanzen
      pflanzen_image_links: {},
      // others
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
      unknownImg,
    };
  },
  computed: {
    data() {
      return this.$store.state.pflanzentypen
        ? this.$store.state.pflanzentypen.find(
            (pt) => pt.id == this.$route.params.id
          )
        : null;
    },
    pflanzen() {
      return this.data && this.$store.getters.pflanzen
        ? this.$store.getters.pflanzen.filter(
            (pflanze) =>
              pflanze.pflanzentyp && pflanze.pflanzentyp.id == this.data.id
          )
        : [];
    },
  },

  watch: {
    pflanzen: {
      // call it upon creation too
      immediate: true,
      handler() {
        this.updateFiles();
      },
    },
  },

  methods: {
    async submit(ev) {
      let data = Object.fromEntries(new FormData(ev.target));
      this.submitLoading = true;
      updateDoc(doc(getFirestore(), `/pflanzentypen/${this.data.id}`), data)
        .catch((err) => {
          this.submitLoading = false;
          this.submitShowError = true;
          setTimeout(() => (this.submitShowError = false), 2500);
          throw err;
        })
        .then(() => {
          this.submitLoading = false;
          this.submitShowSuccess = true;
          setTimeout(() => (this.submitShowSuccess = false), 2500);
        });
    },
    async remove() {
      await deleteDoc(doc(getFirestore(), `/pflanzentypen/${this.data.id}`));
      this.$router.push({
        name: "Pflanzentypen",
      });
    },
    async updateFiles() {
      if (!this.pflanzen) return;
      await Promise.all(
        this.pflanzen
          .filter((p) => p.main_image_name)
          .map((pflanze) =>
            getDownloadURL(
              ref(
                getStorage(),
                `pflanzen/${pflanze.id}/${pflanze.main_image_name}`
              )
            ).then((url) => (this.pflanzen_image_links[pflanze.id] = url))
          )
      );
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
