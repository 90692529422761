<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <pflanzen-typ-neu />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import PflanzenTypNeu from "./components/PflanzenTypNeu.vue";
  
  export default {
    name: "tables",
    components: {
        PflanzenTypNeu,
    },
  };
  </script>
  