<template>
  <div class="form-group">
    <!-- <label :for="id">
      <slot />
    </label> -->
    <textarea
      :id="id"
      :name="name"
      class="form-control"
      rows="5"
      :value="value"
      :placeholder="placeholder"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "SoftTextarea",
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: "Your text here...",
    },
  },
};
</script>
