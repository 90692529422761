import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { collection, getFirestore, onSnapshot, query, orderBy } from "firebase/firestore";
import moment from "moment";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    // data
    pflanzen: null,
    pflanzentypen: null,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    // data
    initPflanzen({ state }) {
      onSnapshot(query(collection(getFirestore(), "/pflanzen"), orderBy('name')), (value) => {
        state.pflanzen = value.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      });
    },
    initPflanzenTypen({ state }) {
      onSnapshot(query(collection(getFirestore(), "/pflanzentypen"), orderBy('name')), (value) => {
        state.pflanzentypen = value.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      });
    },
  },
  getters: {
    pflanzen(state) {
      return state.pflanzen && state.pflanzentypen ? state.pflanzen.map(pflanze => {
        let pflanzentyp = pflanze.pflanzentyp ? state.pflanzentypen.find(pflanzentyp => pflanzentyp.id == pflanze.pflanzentyp.id) : null;
        let zuletztGegossen = pflanze.zuletztGegossen ? {
          date: new Date(pflanze.zuletztGegossen.toDate()),
          moment: moment(pflanze.zuletztGegossen.toDate()),
          timestamp: pflanze.zuletztGegossen,
        } : null;
        let zuletztGedüngt = pflanze.zuletztGedüngt ? {
          date: new Date(pflanze.zuletztGedüngt.toDate()),
          moment: moment(pflanze.zuletztGedüngt.toDate()),
          timestamp: pflanze.zuletztGedüngt,
        } : null;

        return {
          ...pflanze,
          pflanzentyp,
          zuletztGegossen,
          gießen: zuletztGegossen &&
            pflanzentyp && (pflanze.gießenDays || (pflanzentyp && pflanzentyp.gießenDays)) ?
            moment(zuletztGegossen.moment).startOf('day').add(pflanze.gießenDays || pflanzentyp.gießenDays, 'days').isBefore(moment().endOf('day')) : false,
          gießenDaysUntil: zuletztGegossen &&
            pflanzentyp && (pflanze.gießenDays || (pflanzentyp && pflanzentyp.gießenDays)) ?
            moment(zuletztGegossen.moment).startOf('day').add(pflanze.gießenDays || pflanzentyp.gießenDays, 'days').diff(moment().endOf('day'), 'days') : null,
          zuletztGedüngt,
          düngen: zuletztGedüngt &&
            pflanzentyp && (pflanze.düngenDays || (pflanzentyp && pflanzentyp.düngenDays)) ?
            moment(zuletztGedüngt.moment).startOf('day').add(pflanze.düngenDays || pflanzentyp.düngenDays, 'days').isBefore(moment().endOf('day')) : false,
          düngenDaysUntil: zuletztGedüngt &&
            pflanzentyp && (pflanze.düngenDays || (pflanzentyp && pflanzentyp.düngenDays)) ?
            moment(zuletztGedüngt.moment).startOf('day').add(pflanze.düngenDays || pflanzentyp.düngenDays, 'days').diff(moment().endOf('day'), 'days') : null,
        };
      }).sort((a,b) => a.pflanzentyp && b.pflanzentyp ? a.pflanzentyp.name.localeCompare(b.pflanzentyp.name) : 0) : null;
    },
  },
});
