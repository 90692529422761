<template>
  <template v-if="!data">
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative"></div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">Lädt...</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="container-fluid">
      <div
        style="position: absolute; z-index: 1000"
        class="text-white h-100 container-fluid items-between text-white font-weight-bold"
      >
        <div class="row">
          <div class="col-3 text-center">
            <div
              v-if="state_idx != 0"
              @click="
                $router.push({
                  name: 'Pflanze',
                  params: {
                    id: pflanzen[state_idx - 1].id,
                  },
                })
              "
              class="my-8 cursor-pointer"
            >
              ZURÜCK
            </div>
          </div>
          <div class="col-6"></div>
          <div class="col-3 text-center">
            <div
              v-if="state_idx != pflanzen.length - 1"
              @click="
                $router.push({
                  name: 'Pflanze',
                  params: {
                    id: pflanzen[state_idx + 1].id,
                  },
                })
              "
              class="my-8 cursor-pointer"
            >
              WEITER
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                v-if="main_image_url"
                :src="main_image_url"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
              <div v-else>kein Bild</div>
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ data.name || "ohne Name 😒" }}</h5>
              <p
                v-if="data.pflanzentyp"
                class="mb-0 text-sm font-weight-bold cursor-pointer"
                @click="
                  $router.push({
                    name: 'Pflanzentyp',
                    params: {
                      id: data.pflanzentyp.id,
                    },
                  })
                "
              >
                {{ data.pflanzentyp.name }}
                {{
                  data.pflanzentyp.lat_name
                    ? `(${data.pflanzentyp.lat_name})`
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="mt-3 row">
        <div class="col-12 col-md-6 col-xl-4">
          <div class="card h-100">
            <form @submit.stop.prevent="submitAllgemein">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Details</h6>
              </div>
              <div class="p-3 card-body">
                <h6 class="text-xs text-uppercase text-body font-weight-bolder">
                  Allgemeines
                </h6>
                <span class="font-weight-bold">Name</span>
                <soft-input name="name" :value="data.name"></soft-input>
                <div
                  class="text-success underline cursor-pointer"
                  @click="
                    edit_pflanzentyp = true;
                    selected_pflanzentyp = null;
                  "
                >
                  {{
                    !edit_pflanzentyp ? "Pflanzentyp bearbeiten" : "zurücksetzen"
                  }}
                </div>
                <div v-if="edit_pflanzentyp">
                  <h5>Pflanzentyp ändern</h5>
                  <template v-if="!selected_pflanzentyp">
                    <table
                      v-if="pflanzentypen"
                      class="table align-items-center mb-0"
                    >
                      <thead>
                        <tr>
                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Name
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Infos
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="pflanzentyp in pflanzentypen"
                          :key="pflanzentyp.id"
                          class="cursor-pointer"
                          @click="selected_pflanzentyp = pflanzentyp.id"
                        >
                          <td>
                            <div class="d-flex px-2 py-1">
                              <div>
                                <soft-avatar
                                  v-if="false"
                                  :img="null"
                                  size="sm"
                                  border-radius="lg"
                                  class="me-3"
                                  alt="user1"
                                />
                              </div>
                              <div
                                class="d-flex flex-column justify-content-center"
                              >
                                <h6 class="mb-0 text-sm">
                                  {{ pflanzentyp.name }}
                                </h6>
                                <p
                                  v-if="getPflanzen(pflanzentyp.id).length"
                                  class="text-xs text-secondary mb-0"
                                >
                                  {{
                                    getPflanzen(pflanzentyp.id).length == 1
                                      ? "1 Pflanze vorhanden"
                                      : `${
                                          getPflanzen(pflanzentyp.id).length
                                        } Pflanzen vorhanden`
                                  }}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p
                              v-if="
                                pflanzentyp.gießenDays &&
                                pflanzentyp.gießenDays != 0
                              "
                              class="text-xs mb-0"
                            >
                              <span class="font-weight-bold">Gießen </span>
                              <span
                                v-if="pflanzentyp.gießenDays == 1"
                                class="text-secondary"
                              >
                                jeden Tag
                              </span>
                              <span v-else class="text-secondary">
                                aller {{ pflanzentyp.gießenDays }} Tage
                              </span>
                            </p>
                            <p
                              v-if="
                                pflanzentyp.düngenDays &&
                                pflanzentyp.düngenDays != 0
                              "
                              class="text-xs mb-0"
                            >
                              <span class="font-weight-bold">Düngen </span>
                              <span
                                v-if="pflanzentyp.düngenDays == 1"
                                class="text-secondary"
                              >
                                jeden Tag
                              </span>
                              <span v-else class="text-secondary">
                                aller {{ pflanzentyp.düngenDays }} Tage
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      v-if="pflanzentypen && pflanzentypen.length == 0"
                      class="text-center mt-4 mb-3"
                    >
                      Es sind noch keine Pflanzentypen da 🥲
                      <br />
                      <a
                        href="javascript:;"
                        @click="
                          $router.push({
                            name: 'Neuer Pflanzentyp',
                          })
                        "
                        >Jetzt den ersten Pflanzentyp anlegen</a
                      >
                    </div>
                  </template>
                  <div v-else class="mb-4">
                    <div class="mb-2">
                      <span class="font-weight-bolder">Gewählter Pflanzentyp: </span>
                      {{
                        pflanzentypen
                          ? pflanzentypen.find(
                              (pt) => pt.id == selected_pflanzentyp
                            ).name
                          : ""
                      }}
                    </div>
                  </div>
                </div>
                <h6
                  class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
                >
                  Einstellungen
                </h6>
                <ul class="list-group">
                  <li class="px-0 border-0 list-group-item">
                    <soft-switch
                      name="benachrichtigungGießen"
                      class="ps-0 ms-auto"
                      label-class="mb-0 text-body ms-3 text-truncate w-80"
                      :checked="!!data.benachrichtigungGießen"
                    >
                      Benachrichtigung: Gießen
                    </soft-switch>
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <soft-switch
                      name="benachrichtigungDüngen"
                      class="ps-0 ms-auto"
                      label-class="mb-0 text-body ms-3 text-truncate w-80"
                      :checked="!!data.benachrichtigungDüngen"
                    >
                      Benachrichtigung: Düngen
                    </soft-switch>
                  </li>
                </ul>
                <soft-button type="submit" :disabled="submitAllgemeinLoading">
                  Daten aktualisieren
                </soft-button>
                <small
                  v-if="submitAllgemeinShowSuccess"
                  class="mx-3 text-success"
                >
                  Daten aktualisiert
                </small>
                <small
                  v-if="submitAllgemeinShowError"
                  class="mx-3 text-warning"
                >
                  Fehler aufgetreten
                </small>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
          <div class="card h-100">
            <div class="p-3 pb-0 card-header">
              <div class="row">
                <div class="col-md-8 d-flex align-items-center">
                  <h6 class="mb-0">Informationen zur Pflanze</h6>
                </div>
                <div class="col-md-4 text-end">
                  <a href="javascript:;" @click="showGießenDays = !!data.gießenDays; showDüngenDays = !!data.düngenDays; editDetails = !editDetails; ">
                    <i
                      class="text-sm fas fa-edit text-secondary"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :title="!editDetails ? 'Bearbeiten' : 'Abbruch'"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
            <div v-if="!editDetails" class="p-3 card-body">
              <p class="text-sm">
                {{ data.beschreibung || "(noch) keine Beschreibung vorhanden" }}
              </p>

              <div>
                <div v-if="data.gießenDays || data.pflanzentyp.gießenDays">
                  Gießen (aller {{ data.gießenDays || data.pflanzentyp.gießenDays }} Tage)
                </div>
                <div v-if="data.düngenDays || data.pflanzentyp.düngenDays">
                  Düngen (aller {{ data.düngenDays || data.pflanzentyp.düngenDays }} Tage)
                </div>
              </div>
              
              <ul class="list-group mt-3">
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Zuletzt gegossen:</strong> &nbsp;
                  {{
                    data.zuletztGegossen
                      ? data.zuletztGegossen.moment.format("DD. MMM YYYY HH:mm")
                      : "unbekannt"
                  }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Zuletzt gedüngt:</strong> &nbsp;
                  {{
                    data.zuletztGedüngt
                      ? data.zuletztGedüngt.moment.format("DD. MMM YYYY HH:mm")
                      : "unbekannt"
                  }}
                </li>
              </ul>
            </div>
            <div v-else class="p-3 card-body">
              <form @submit.prevent.stop="submitDetails">
                <span class="font-weight-bold">Beschreibung</span>
                <soft-textarea
                  name="beschreibung"
                  :value="data.beschreibung"
                  placeholder="(noch) keine Beschreibung vorhanden"
                ></soft-textarea>
                <soft-checkbox :checked="showGießenDays" @input="(val) => showGießenDays = val">Gießen-Tage abweichend von Pflanzentyp</soft-checkbox>
                <soft-input
                  v-if="showGießenDays"
                  name="gießenDays"
                  type="number"
                  :value="data.gießenDays ? data.gießenDays.toString() : 5"
                ></soft-input>
                <soft-checkbox :checked="showDüngenDays" @input="(val) => showDüngenDays = val">Düngen-Tage abweichend von Pflanzentyp</soft-checkbox>
                <soft-input
                  v-if="showDüngenDays"
                  name="düngenDays"
                  type="number"
                  :value="data.düngenDays ? data.düngenDays.toString() : 5"
                ></soft-input>
                <soft-button type="submit" :disabled="submitDetailsLoading" class="mt-3">
                  Daten aktualisieren
                </soft-button>
                <small
                  v-if="submitDetailsShowSuccess"
                  class="mx-3 text-success"
                >
                  Daten aktualisiert
                </small>
                <small v-if="submitDetailsShowError" class="mx-3 text-warning">
                  Fehler aufgetreten
                </small>
              </form>
            </div>
          </div>
        </div>
        <div class="mt-4 col-12 col-xl-4 mt-xl-0">
          <div class="card h-100">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-0">Aktionen</h6>
            </div>
            <div class="p-3 card-body">
              <ul class="list-group">
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="sophie"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">
                      Gießen (aller {{ (data.gießenDays || data.pflanzentyp.gießenDays) }} Tage)
                    </h6>
                    <p v-if="!data.zuletztGegossen" class="mb-0 text-xs">
                      keine Informationen vorhanden
                    </p>
                    <p
                      v-else-if="data.gießen && data.gießenDaysUntil == 0"
                      class="mb-0 text-xs"
                    >
                      muss heute gegossen werden
                    </p>
                    <p v-else-if="data.gießen" class="mb-0 text-xs">
                      muss gegossen werden seit: <br />{{
                        moment(data.zuletztGegossen.moment)
                          .add(data.gießenDaysUntil, "days")
                          .startOf("day")
                          .format("DD. MMM YYYY")
                      }}
                    </p>
                    <p v-else class="mb-0 text-xs">
                      muss gegossen werden am: <br />{{
                        moment(data.zuletztGegossen.moment)
                          .add(data.gießenDaysUntil, "days")
                          .startOf("day")
                          .format("DD. MMM YYYY")
                      }}
                    </p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    @click="gießen()"
                  >
                    Jetzt gießen
                  </a>
                </li>
                <li
                  class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                >
                  <soft-avatar
                    class="me-3"
                    :img="sophie"
                    alt="kal"
                    border-radius="lg"
                    shadow="regular"
                  />
                  <div
                    class="d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 class="mb-0 text-sm">
                      Düngen (aller {{ (data.düngenDays || data.pflanzentyp.düngenDays) }} Tage)
                    </h6>
                    <p v-if="!data.zuletztGedüngt" class="mb-0 text-xs">
                      keine Informationen vorhanden
                    </p>
                    <p
                      v-else-if="data.düngen && data.düngenDaysUntil == 0"
                      class="mb-0 text-xs"
                    >
                      muss heute gedüngt werden
                    </p>
                    <p v-else-if="data.düngen" class="mb-0 text-xs">
                      muss gedüngt werden seit: <br />{{
                        moment(data.zuletztGedüngt.moment)
                          .add(data.düngenDaysUntil, "days")
                          .startOf("day")
                          .format("DD. MMM YYYY")
                      }}
                    </p>
                    <p v-else class="mb-0 text-xs">
                      muss gedüngt werden am: <br />{{
                        moment(data.zuletztGedüngt.moment)
                          .add(data.düngenDaysUntil, "days")
                          .startOf("day")
                          .format("DD. MMM YYYY")
                      }}
                    </p>
                  </div>
                  <a
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                    href="javascript:;"
                    @click="düngen()"
                  >
                    Jetzt düngen
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-12">
          <div class="mb-4 card">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-1">Bilder</h6>
            </div>
            <div class="p-3 card-body">
              <div class="row">
                <default-pflanze-card
                  v-for="file of files"
                  :key="file.url"
                  :title="file.name || 'ohne Name 😒'"
                  :image="file.url"
                  :action="
                    file.name == data.main_image_name
                      ? {
                          color: 'info',
                          label: 'Hauptbild',
                        }
                      : {
                          color: 'success',
                          label: 'Als Hauptbild kennzeichnen',
                          clickHandler() {
                            update({
                              main_image_name: file.name,
                            }).then(() => getFiles());
                          },
                        }
                  "
                  @delete="deleteFile(file.name)"
                />

                <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
                  <place-holder-card
                    :title="{ text: 'Bild hinzufügen', variant: 'h5' }"
                    @click="uploadFile()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <a href="#" @click="remove">
        <i
          class="text-sm fas fa-trash text-secondary"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Löschen"
        ></i>
        Pflanze Löschen
      </a>
    </div>
  </template>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftTextarea from "@/components/SoftTextarea.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import DefaultPflanzeCard from "./components/DefaultPflanzeCard.vue";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import moment from "moment";
import { updateDoc, doc, getFirestore, deleteDoc } from "@firebase/firestore";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import { fileDialog } from "file-select-dialog";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    SoftInput,
    SoftTextarea,
    SoftButton,
    SoftCheckbox,
    SoftAvatar,
    DefaultPflanzeCard,
    PlaceHolderCard,
  },
  data() {
    return {
      submitAllgemeinLoading: false,
      submitAllgemeinShowSuccess: false,
      submitAllgemeinShowError: false,
      editDetails: false,
      submitDetailsLoading: false,
      submitDetailsShowSuccess: false,
      submitDetailsShowError: false,
      // files
      files: [],
      main_image_url: "",
      // edit-helpers
      edit_pflanzentyp: false,
      selected_pflanzentyp: null,
      showGießenDays: false,
      showDüngenDays: false,
      // others
      moment,
      Date,
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
    };
  },
  computed: {
    ...mapState(["pflanzentypen"]),
    ...mapGetters(["pflanzen"]),
    data() {
      return this.pflanzen
        ? this.pflanzen.find(
            (pt) => pt.id == this.$route.params.id
          )
        : null;
    },
    state_idx() {
      return this.pflanzen
        ? this.pflanzen.findIndex(
            (pt) => pt.id == this.$route.params.id
          )
        : null;
    },
  },

  watch: {
    data: {
      // call it upon creation too
      immediate: true,
      handler() {
        this.getFiles();
      },
    },
  },

  methods: {
    getPflanzen(typ_id) {
      return (this.pflanzen || []).filter(
        (pflanze) => pflanze.pflanzentyp && pflanze.pflanzentyp.id == typ_id
      );
    },
    async getFiles() {
      if (!this.data) return;
      const listRef = ref(getStorage(), `pflanzen/${this.data.id}`);
      let response = await listAll(listRef);
      let files = response.items.map((reference) => {
        return {
          reference,
          name: reference.name,
        };
      });
      await Promise.all(
        files.map(async (file) =>
          getDownloadURL(file.reference).then((url) => (file.url = url))
        )
      );
      this.files = files;
      let main_image_file = this.files.find(
        (file) => file.name == this.data.main_image_name
      );
      if (this.data)
        this.main_image_url = main_image_file ? main_image_file.url : "";
    },
    async uploadFile() {
      let file = await fileDialog({
        accept: "image/*",
      }).then((fileList) => fileList[0]);
      await uploadBytes(
        ref(getStorage(), `pflanzen/${this.data.id}/${file.name}`),
        file
      ).catch((err) => {
        alert(err.toString());
        throw err;
      });
      await this.getFiles();
    },
    async deleteFile(fileName) {
      await deleteObject(
        ref(getStorage(), `pflanzen/${this.data.id}/${fileName}`)
      );
      if (this.data.main_image_name == fileName)
        await this.update({
          main_image_name: null,
        });
      await this.getFiles();
    },
    async update(data) {
      return updateDoc(doc(getFirestore(), `/pflanzen/${this.data.id}`), data);
    },
    async submitAllgemein(ev) {
      let data = Object.fromEntries(new FormData(ev.target));
      if (this.selected_pflanzentyp)
        data.pflanzentyp = doc(
          getFirestore(),
          `/pflanzentypen/${this.selected_pflanzentyp}`
        );
      this.submitAllgemeinLoading = true;
      this.update({
        ...data,
        benachrichtigungDüngen: !!data.benachrichtigungDüngen,
        benachrichtigungGießen: !!data.benachrichtigungGießen,
      })
        .catch((err) => {
          this.submitAllgemeinLoading = false;
          this.submitAllgemeinShowError = true;
          setTimeout(() => (this.submitAllgemeinShowError = false), 2500);
          throw err;
        })
        .then(() => {
          this.submitAllgemeinLoading = false;
          this.submitAllgemeinShowSuccess = true;
          this.edit_pflanzentyp = false;
          this.selected_pflanzentyp = null;
          setTimeout(() => (this.submitAllgemeinShowSuccess = false), 2500);
        });
    },
    async submitDetails(ev) {
      let data = Object.fromEntries(new FormData(ev.target));
      if (!data.gießenDays) data.gießenDays = null;
      if (!data.düngenDays) data.düngenDays = null;
      this.submitDetailsLoading = true;
      this.update(data)
        .catch((err) => {
          this.submitDetailsLoading = false;
          this.submitDetailsShowError = true;
          setTimeout(() => (this.submitDetailsShowError = false), 2500);
          throw err;
        })
        .then(() => {
          this.submitDetailsLoading = false;
          this.submitDetailsShowSuccess = true;
          setTimeout(() => {
            this.submitDetailsShowSuccess = false;
            this.editDetails = false;
          }, 1500);
        });
    },
    async gießen() {
      if (confirm("Pflanze jetzt gießen?"))
        return this.update({
          zuletztGegossen: new Date(),
        });
    },
    async düngen() {
      if (confirm("Pflanze jetzt düngen?"))
        return this.update({
          zuletztGedüngt: new Date(),
        });
    },
    async remove() {
      await deleteDoc(doc(getFirestore(), `/pflanzen/${this.data.id}`));
      this.$router.push({
        name: "Pflanzen",
      });
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
