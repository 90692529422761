<template>
  <div class="card mb-4">
    <div class="card-header pb-0 block">
      <div class="row">
        <h6 class="col">Pflanzen</h6>
        <div class="col-auto text-right">
          <soft-button
            @click="
              $router.push({
                name: 'Neue Pflanze',
              })
            "
            >Neue Pflanze</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table v-if="pflanzen" class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                TODOs
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pflanze in pflanzen" :key="pflanze.id">
              <td>
                <div
                  class="d-flex px-2 py-1 cursor-pointer"
                  @click="
                    $router.push({
                      name: 'Pflanze',
                      params: { id: pflanze.id },
                    })
                  "
                >
                  <div>
                    <soft-avatar
                      v-if="pflanzen_image_links[pflanze.id]"
                      :img="pflanzen_image_links[pflanze.id]"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ pflanze.name }}</h6>
                    <p
                      v-if="pflanze.pflanzentyp"
                      class="text-xs text-secondary mb-0"
                    >
                      {{ pflanze.pflanzentyp.name }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p v-if="pflanze.zuletztGegossen" class="text-xs mb-0">
                  <span class="font-weight-bold">zuletzt gegossen: </span>
                  <span class="text-secondary">{{
                    pflanze.zuletztGegossen.moment.format("DD. MMM YYYY HH:mm")
                  }}</span>
                </p>
                <p v-if="pflanze.zuletztGedüngt" class="text-xs mb-0">
                  <span class="font-weight-bold">zuletzt gedüngt: </span>
                  <span class="text-secondary">{{
                    pflanze.zuletztGedüngt.moment.format("DD. MMM YYYY HH:mm")
                  }}</span>
                </p>
                <p v-if="!pflanze.zuletztGegossen" class="text-xs mb-0">
                  noch nie gegossen
                </p>
                <p v-if="!pflanze.zuletztGedüngt" class="text-xs mb-0">
                  noch nie gedüngt
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <soft-badge
                  v-if="!pflanze.zuletztGedüngt"
                  color="secondary"
                  variant="gradient"
                  size="sm"
                  class="m-1 mb-0 mt-0 cursor-pointer"
                  @click="jetztDüngen(pflanze.id)"
                >
                  DÜNGEN
                </soft-badge>
                <soft-badge
                  v-if="pflanze.düngen"
                  color="warning"
                  variant="gradient"
                  size="sm"
                  class="m-1 mb-0 mt-0 cursor-pointer"
                  @click="jetztDüngen(pflanze.id)"
                >
                  <!-- eslint-disable vue/no-parsing-error -->
                  DÜNGEN{{
                    pflanze.düngenDaysUntil < 0
                      ? "( " + pflanze.düngenDaysUntil * -1 + " überfällig)"
                      : ""
                  }}
                  <!-- eslint-enable vue/no-parsing-error -->
                </soft-badge>
                <soft-badge
                  v-if="!pflanze.zuletztGegossen"
                  color="secondary"
                  variant="gradient"
                  size="sm"
                  class="m-1 mb-0 mt-0 cursor-pointer"
                  @click="jetztGießen(pflanze.id)"
                >
                  GIEßEN
                </soft-badge>
                <soft-badge
                  v-if="pflanze.gießen"
                  color="warning"
                  variant="gradient"
                  size="sm"
                  class="m-1 mb-0 mt-0 cursor-pointer"
                  @click="jetztGießen(pflanze.id)"
                >
                  <!-- eslint-disable vue/no-parsing-error -->
                  GIEßEN{{
                    pflanze.gießenDaysUntil < 0
                      ? "( " + pflanze.gießenDaysUntil * -1 + " überfällig)"
                      : ""
                  }}
                  <!-- eslint-enable vue/no-parsing-error -->
                </soft-badge>
              </td>
              <td class="align-middle">
                <a
                  href="javascript:;"
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Pflanze bearbeiten"
                  @click="
                    $router.push({
                      name: 'Pflanze',
                      params: { id: pflanze.id },
                    })
                  "
                  >Details</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="pflanzen && pflanzen.length == 0"
          class="text-center mt-4 mb-3"
        >
          Es sind noch keine Pflanzen da 🥲
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftButton from "@/components/SoftButton.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { doc, getFirestore, updateDoc } from "@firebase/firestore";
import { getStorage, ref, getDownloadURL } from "@firebase/storage";

export default {
  name: "authors-table",
  data() {
    return {
      moment,
      pflanzen_image_links: {},
    };
  },
  components: {
    SoftAvatar,
    SoftBadge,
    SoftButton,
  },
  computed: {
    ...mapGetters(["pflanzen"]),
  },
  watch: {
    pflanzen: {
      immediate: true,
      handler() {
        this.updateFiles();
      },
    },
  },
  methods: {
    jetztGießen(id) {
      if (!confirm("Die Pflanze jetzt gießen?")) return;
      return updateDoc(doc(getFirestore(), `/pflanzen/${id}`), {
        zuletztGegossen: new Date(),
      });
    },
    jetztDüngen(id) {
      if (!confirm("Die Pflanze jetzt düngen?")) return;
      return updateDoc(doc(getFirestore(), `/pflanzen/${id}`), {
        zuletztGedüngt: new Date(),
      });
    },
    async updateFiles() {
      if (!this.pflanzen) return;
      await Promise.all(
        this.pflanzen
          .filter((p) => p.main_image_name)
          .map((pflanze) =>
            getDownloadURL(
              ref(
                getStorage(),
                `pflanzen/${pflanze.id}/${pflanze.main_image_name}`
              )
            ).then((url) => (this.pflanzen_image_links[pflanze.id] = url))
          )
      );
    },
  },
};
</script>
